import React from "react"
import {Col, Container, Row} from "react-bootstrap"

const Footer = (props) => (

    <Container fluid className="px-0">
        <Row noGutters>
            <Col className="footer-col">
                <footer>
                <span>
                  © {new Date().getFullYear()} {props.siteTitle} {props.children}
                </span>
                </footer>
            </Col>
        </Row>
    </Container>
)

export default Footer