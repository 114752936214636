import React from "react"
import Carousel from 'react-bootstrap/Carousel'
import {Link} from 'gatsby';
import Img from 'gatsby-image';
import YouTube from 'react-youtube';


//https://codepen.io/gza/pen/GMzzRg
const CustomNavbarToggle = (props) => {

    return (
		<>
			<span></span>
			<span></span>
			<span></span>
		</>
    )
}

export default CustomNavbarToggle
