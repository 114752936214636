import React from "react"
import Img from "gatsby-image"
import {Col, Container, Row} from "react-bootstrap"
import {FaInstagram, FaFax, FaPhone, FaSms, FaFacebookMessenger, FaFacebookSquare, FaMobileAlt, FaRegEnvelope, FaWhatsapp, FaYoutube, FaMapSigns, FaMapMarkerAlt} from "react-icons/fa";
import QRCode from "qrcode.react"

const PreFooter = (props) => {
	
	let smsAnchor = (<></>)
	if(props.footerInfo.sms!= undefined){
		smsAnchor = (
                    <li><a rel="noopener noreferrer" target="_blank"
                           href={`sms:${props.footerInfo.sms.phone}`}><FaSms/><span>{props.footerInfo.sms.txt}</span></a>
                    </li>
		)
	}
	
	return (

    <Container id="contact" fluid className="pre-footer py-5">
        <Row>
            <Col className="text-center" md={4}>
                <h1><Img fixed={props.logo.childImageSharp.fixed} alt={props.siteTitle}/></h1>
				<h6>{props.claim}</h6>
                <QRCode className="mt-1 small-qr" value={`tel:${props.footerInfo.mobile.phone}`}/>
				<h6 className="mt-3">SEGUICI SU</h6>
				<div className="pre-footer-social">
				{
					props.footerInfo.facebook!= undefined ? (
					<a rel="noopener noreferrer" target="_blank" title="FACEBOOK" 
					   href={props.footerInfo.facebook}><FaFacebookSquare/></a>
					) : (<></>)
				}
				{
					props.footerInfo.instagram!= undefined ? (
					<a rel="noopener noreferrer" target="_blank" title="INSTAGRAM" 
					   href={props.footerInfo.instagram}><FaInstagram/></a>
					) : (<></>)
				}
				{
					props.footerInfo.youtube!= undefined ? (
					<a rel="noopener noreferrer" target="_blank" title="YOUTUBE" 
					   href={props.footerInfo.youtube}><FaYoutube/></a>
					) : (<></>)
				}
				</div>
            </Col>
            <Col className="pre-footer-contacts" md={4}>
                <ul>
				    {
						props.footerInfo.fix!= undefined ? (
                    <li><a rel="noopener noreferrer" target="_blank" href={`tel:${props.footerInfo.fix.phone}`}><FaPhone/><span> {props.footerInfo.fix.txt}</span></a>
                    </li>
						) : (<></>)
					}
				    {
						props.footerInfo.fax!= undefined ? (
                    <li><a rel="noopener noreferrer" target="_blank" href={`tel:${props.footerInfo.fax.phone}`}><FaFax/><span> {props.footerInfo.fax.txt}</span></a>
                    </li>
						) : (<></>)
					}
				    {
						props.footerInfo.mobile!= undefined ? (
                    <li><a rel="noopener noreferrer" target="_blank" href={`tel:${props.footerInfo.mobile.phone}`}><FaMobileAlt/><span> {props.footerInfo.mobile.txt}</span></a>
                    </li>
						) : (<></>)
					}
					{
						props.footerInfo.email!= undefined ? (
                    <li><a rel="noopener noreferrer" target="_blank"
                           href={`mailto:${props.footerInfo.email.mail}?subject="` + encodeURIComponent(props.footerInfo.email.message) +`"`}><FaRegEnvelope/><span>  {props.footerInfo.email.txt} </span></a>
                    </li>
						) : (<></>)
					}
					{
						props.footerInfo.wa!= undefined ? (
                    <li><a rel="noopener noreferrer" target="_blank"
                           href={`https://wa.me/${props.footerInfo.wa.phone}?text=` + encodeURIComponent(props.footerInfo.wa.message)}><FaWhatsapp/><span>
						   {props.footerInfo.wa.txt} </span></a>
                    </li>
						) : (<></>)
					}
					{
						props.footerInfo.me!= undefined ? (
                    <li><a rel="noopener noreferrer" target="_blank"
                           href={props.footerInfo.me}><FaFacebookMessenger/><span> MESSENGER </span></a>
                    </li>
						) : (<></>)
					}
					{smsAnchor}
					{
						props.footerInfo.map!= undefined ? (
                    <li><a rel="noopener noreferrer" target="_blank"
                           href={props.footerInfo.map}><FaMapMarkerAlt/><span>MAPPA</span></a>
                    </li>
						) : (<></>)
					}
					{
						props.footerInfo.direction!= undefined ? (
                    <li><a rel="noopener noreferrer" target="_blank"
                           href={props.footerInfo.direction}><FaMapSigns/><span>INDICAZIONI</span></a>
                    </li>
						) : (<></>)
					}
					
					{props.children}
                </ul>
            </Col>
            <Col className="text-center" md={4}>
		<a target="_blank" href="https://www.matrimonio.com/musica-matrimonio/ciro-triassi-music-live--e83656" rel="nofollow" title="Ciro Triassi Music Live, vincitore Wedding Awards 2023 Matrimonio.com">
			<img width="125" height="125" alt="Ciro Triassi Music Live, vincitore Wedding Awards 2023 Matrimonio.com" id="wp-ratedWA-img-2023" src="https://cdn1.matrimonio.com/img/badges/2023/badge-weddingawards_it_IT.jpg"/>
				</a>
            </Col>
        </Row>
    </Container>
)
}

export default PreFooter
