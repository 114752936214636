/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import {graphql, useStaticQuery} from "gatsby"
import {Col, Container, Row} from "react-bootstrap"
import Navbar from "./navBar"
import Footer from "../../plugins/gatsby-theme-chegatsby/src/components/footer"
import PreFooter from "../../plugins/gatsby-theme-chegatsby/src/components/preFooter"

const Layout = ({children, pageInfo, footerInfo}) => {


	const commonFooterInfo = {
	  facebook: "https://www.facebook.com/cirotriassipianobar.triassi",
	  youtube: "https://www.youtube.com/channel/UC-9UuNz3P4JH9BAhwnCGxKA",
	  mobile: {
		  phone: "+393807040490",
		  txt: "(+39) 380 70 40 490"
	  },							
	  email : {
		  mail: "cirotriassi@alice.it",
		  txt: "cirotriassi@alice.it",
		  message: "Vorrei informazioni su"
	  },
	  wa : {
		  phone: "+393807040490",
		  txt: "(+39) 380 7040490",
		  message: "Vorrei informazioni su"
	  },
	  me : "http://m.me/cirotriassipianobar.triassi?ref=info",
	  sms: {
		  phone: "3807040490",
		  txt: "(+39) 380 7040490",
		  message: "Vorrei informazioni su"
	  }
	}

	let footer_info = (footerInfo == null ) ? commonFooterInfo : footerInfo
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
            file(relativePath: {eq: "logo-bg-light.png"}) {
                childImageSharp {
                    fixed(height: 60) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)
	
	if (typeof window !== "undefined") {
	  // eslint-disable-next-line global-require
	  require("smooth-scroll")('a[href*="#"]')
	}

    return (
        <>
            <Container fluid className="px-0 main">
                {/*
                <Row noGutters className="justify-content-center">
                    <Col>
                      <Header siteTitle={data.site.siteMetadata.title} centered={true} logo={data.file}/>
                    </Col>
                </Row>
                */}
                <Navbar pageInfo={pageInfo} siteTitle={data.site.siteMetadata.title} logo={data.file}/>
                <Row noGutters>
                    <Col>
                        <main>{children}</main>
                    </Col>
                </Row>
            </Container>
            <PreFooter logo={data.file} siteTitle={data.site.siteMetadata.title} footerInfo={footer_info}/>
            <Footer siteTitle={data.site.siteMetadata.title}/>
        </>
    )
}

export default Layout
