import React from "react"
import {Link} from "gatsby"
import Img from "gatsby-image"
import {Nav, Navbar} from "react-bootstrap"
import CustomNavbarToggle from '../../plugins/gatsby-theme-chegatsby/src/components/customNavbarToggle'

const CustomNavbar = ({siteTitle, pageInfo, logo}) => {

//const navbarAlign = "m-auto"
//const navbarAlign = "mr-auto"
    const navbarAlign = "ml-auto"

    return (
        <>
            <Navbar variant="light" expand="lg" id="site-navbar">
                {/* <Container> */}
                <Link to="/" className="link-no-style">
                    <Navbar.Brand as="span"><Img fixed={logo.childImageSharp.fixed} alt={siteTitle}/></Navbar.Brand>
                </Link>
                <Navbar.Toggle aria-controls="basic-navbar-nav" className="x"><CustomNavbarToggle/></Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className={navbarAlign} activeKey={pageInfo && pageInfo.pageName}>
                        <Link to="/chi-siamo" className="link-no-style" activeClassName="active">
                            <Nav.Link as="span" eventKey="chi-siamo">
                                Chi siamo
                            </Nav.Link>
                        </Link>
                        <Link to="/promozioni" className="link-no-style" activeClassName="active">
                            <Nav.Link as="span" eventKey="promo">
                                Promozioni
                            </Nav.Link>
                        </Link>
                        <Link to="/video-portfolio" className="link-no-style" activeClassName="active">
                            <Nav.Link as="span" eventKey="video">
                                Video
                            </Nav.Link>
                        </Link>
                        <Link to="#contact" className="link-no-style">
                            <Nav.Link as="span" eventKey="contact">
                                Contatti
                            </Nav.Link>
                        </Link>
                    </Nav>
                    {/*<Nav className="ml-auto">
            <Form inline onSubmit={e => e.preventDefault()}>
              <Form.Group>
                <FormControl
                  type="text"
                  placeholder="Fake Search"
                  className="mr-2"
                />
              </Form.Group>
              <Button>Fake Button</Button>
            </Form>
          </Nav>*/}
                </Navbar.Collapse>
                {/* </Container> */}
            </Navbar>
        </>
    )
}

export default CustomNavbar
